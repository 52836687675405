import wx from 'weixin-js-sdk';
import { toast } from '../util';
import { getJssdkConfig } from '../services/wechat';

export default {
    methods: {
        async getLocation() {
            const { code, message, data } = await getJssdkConfig(this.$store.state.wxConfigUrl);
            return new Promise((resolve, reject) => {
                if (!code) {
                    reject();
                    return toast(message);
                }
                const { app_id, timestamp, nonce_str, signature } = data;
                wx.config({
                    debug: false,
                    appId: app_id,
                    timestamp,
                    nonceStr: nonce_str,
                    signature: signature,
                    jsApiList: ['getLocation', 'openLocation']
                });
                wx.error(function(res) {
                    reject();
                    return toast(res.errMsg);
                });
                wx.ready(function() {
                    wx.getLocation({
                        type: 'gcj02',
                        success: function(res) {
                            const { longitude, latitude } = res;
                            resolve({ longitude, latitude });
                        },
                        cancel: function(res) {
                            reject();
                            return toast(res.msg);
                        }
                    });
                });
            });
        }
    }
};
